import React from "react";
import { Link } from "gatsby";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import dayjs from "dayjs";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Butter from "buttercms";
import SEO from "../components/seo";

const butter = Butter("09ba7d6ba30c1ad49fcdae19b8bed0ce840ca696");

export default () => {
  const [state, setState] = React.useState({
    currentPage: 1,
    next_page: "",
    posts: [],
  });

  React.useEffect(() => {
    let page = 1;
    if (typeof window !== "undefined" && window.location.search) {
      const page = window.location.search.substr(6);
    }
    getPostsForPage(page);
  }, []);

  const handleNextClicked = React.useCallback(() => {
    const nextPage = state.next_page;
    getPostsForPage(nextPage);
  }, [state.next_page]);

  const handleBackClicked = React.useCallback(() => {
    const backPage = state.currentPage - 1;
    getPostsForPage(backPage);
  }, [state.currentPage]);

  const getPostsForPage = (page) => {
    butter.post
      .list({
        page,
        page_size: 12,
        exclude_body: "true",
      })
      .then((response) => {
        setState({
          currentPage: page,
          posts: response.data.data,
          next_page: response.data.meta.next_page,
        });
      });
  };

  return (
    <>
      <SEO
        title="All posts"
        keywords={[
          "blog",
          "lablog",
          "lab notebook blog",
          "learn more about lablog",
        ]}
      />

      <Container maxWidth="md">
        <Typography component="div">
          <Box
            textAlign="center"
            component="h1"
            m={1}
            fontFamily="DM Serif Text"
            fontWeight="700"
            color="#3d3d3d"
            fontSize="h2.fontSize"
          >
            LabLog Announcements &amp; News
          </Box>
          <br />
        </Typography>
        <Grid container spacing={1}>
          {state.posts.map((post, index) => (
            <Grid container item md={4} key={index}>
              <Card key={index} style={{ width: "100%" }}>
                <CardMedia
                  component="img"
                  alt="Blog Post Image"
                  height="160"
                  image={post.featured_image}
                  title="Contemplative Reptile"
                />
                <CardHeader
                  title={post.title}
                  subheader={dayjs(post.published).format("MMM DD YYYY")}
                />
                <CardContent>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {post.summary}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Link to={post.slug}>
                    <Button size="small" color="primary">
                      Read more
                    </Button>
                  </Link>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        <br />
        <br />
        <br />
        <Box display="flex" justifyContent="center" m={1} p={1}>
          <Box p={1} hidden={state.currentPage - 1 < 1}>
            <Link
              to={`/blog?page=${state.currentPage - 1}`}
              onClick={handleBackClicked}
            >
              &larr; Page {state.currentPage - 1} &nbsp;&nbsp;&nbsp;&nbsp;
            </Link>
          </Box>
          <Box p={1} bgcolor="grey.300">
            Current Page: {state.currentPage}
          </Box>
          <Box p={1} hidden={state.next_page === null}>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Link
              to={`/blog?page=${state.next_page}`}
              onClick={handleNextClicked}
            >
              Page {state.next_page} &rarr;
            </Link>
          </Box>
        </Box>

        <br />
        <br />
        <br />
        <br />
        <br />
      </Container>
    </>
  );
};
